<template>
    <div>
        <v-row><v-col cols="12"></v-col></v-row>

        <v-row>
            <v-col cols="12" sm="12" md="4" lg="2" xl="2" v-if="brokerMap.length > 1">
                <v-autocomplete label="Filter Broker"
                    v-model="selectedBrokerId" :items="brokerMap" item-text="broker" item-value="broker_id"
                    auto-select-first chips clearable deletable-chips small-chips></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                <v-autocomplete label="Filter Book (Optional)"
                    v-model="selectedBook" :items="filterBookLists" item-text="book" item-value="book"
                    auto-select-first multiple chips deletable-chips small-chips></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                <v-text-field label="Amount (Greater or Equal)" v-model="selectedAmount" hide-details="auto"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                <v-text-field label="Login (Optional)" v-model="selectedLogin" hide-details="auto"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                <v-autocomplete label="Filter Type"
                    v-model="selectedType" :items="filterTypeLists" item-text="text" item-value="value"
                    auto-select-first chips deletable-chips small-chips></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                <v-datetime-picker label="Start Date" v-model="selectedStartDate"></v-datetime-picker>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                <v-datetime-picker label="End Date" v-model="selectedEndDate"></v-datetime-picker>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <v-btn v-on:click="searchAlert()" :loading="btnLoading" color="primary">SEARCH</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <DataTableSearch :headers="tableHeader" :itemsPerPage="itemsPerPage" :dataSource="tableData" :name="'wd'" />
            </v-col>
        </v-row>
    </div>
</template>
<script>
import * as apiSearchAlertWithdrawDeposit from "@components/api/pk/search/alert/search-alert-withdraw-deposit";
import { mapState } from "vuex";
import { helper } from "@components/mixins/helper";
import { snackbar } from "@components/mixins/snackbar";
import { permission } from "@components/mixins/permission";
import { helperApi } from "@components/mixins/helper-api";
import DataTableSearch from "./DataTable";

export default {
    components: { DataTableSearch },
    mixins: [helper, permission, snackbar, helperApi],
    data () {
        return {
            filterTypeLists: [{ text: "Withdraw", value: "Withdraw" }, { text: "Deposit", value: "Deposit" }],
            tableData: [],
            itemsPerPage: 20,
            tableHeader: [
                { text: "Login", value: "login" },
                { text: "Book", value: "book" },
                { text: "Amount", value: "amount" },
                { text: "Order", value: "order" },
                { text: "Thredshold Amount", value: "thredshold_amount" },
                { text: "Time", value: "time" }
                // { text: "Message", value: "message" },
                // { text: "Created At", value: "created_at" },
                // { text: "Updated At", value: "updated_at" },
            ],
            selectedStartDate: null,
            selectedEndDate: null,
            selectedType: "Withdraw",
            selectedBrokerId: 1,
            selectedAmount: 1,
            selectedBook: [],
            selectedLogin: "",
            filterBookLists: [],
            btnLoading: false
        };
    },
    computed: {
        ...mapState(["brokerMap"])
    },
    watch: {
        selectedBrokerId (nv, ov) {
            this.filterBookLists = this.getBookListByBrokerId(nv);
        }
    },
    methods: {
        searchAlert () {
            const self = this;
            const params = {
                broker: self.getBrokerNameById(self.selectedBrokerId),
                start_date: self.selectedStartDate,
                end_date: self.selectedEndDate,
                login: self.selectedLogin,
                book: self.selectedBook,
                amount: self.selectedAmount,
                type: self.selectedType
            };
            console.log(params);
            self.btnLoading = true;
            apiSearchAlertWithdrawDeposit.create(params).then(res => {
                console.log(res.data);
                self.tableData = res.data;
                self.btnLoading = false;
                if (res.data.length === 0) {
                    self.snackBarInfo("No data found!");
                } else {
                    self.snackBarSuccess("Successfully");
                }

                self.tableData = res.data;
            });
        }
    },
    created () {
        // 获取当前账户对应权限的broker及broker id
        const brokerPermission = this.loadDefaultBrokerData();
        this.selectedBrokerId = brokerPermission.selectedBrokerId;
        this.selectedBroker = brokerPermission.selectedBroker;
    },
    mounted () {
        const currentDate = new Date();
        let dateOffset = (24 * 60 * 60 * 1000) * 1; // 5 days

        currentDate.setTime(currentDate.getTime() + dateOffset);
        this.selectedEndDate = currentDate;

        dateOffset = (24 * 60 * 60 * 1000) * 20; // 20 days

        const startDate = new Date();

        startDate.setTime(startDate.getTime() - dateOffset);

        this.selectedStartDate = startDate;

        this.filterBookLists = this.getBookListByBrokerId(this.selectedBrokerId);
    }
};
</script>
