import * as apiBook from "@components/api/pk/book";

export const helperApi = {
    methods: {
        async loadBook (brokerId) {
            const params = { broker_id: brokerId };
            apiBook.fetch(params).then(res => {
                return res.data;
            });
        }
    }
};
