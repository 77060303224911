<template>
    <div>

        <v-row>
            <v-col cols="12">
                <v-tabs>
                    <v-tab v-if="bigLot == 1" v-on:click="changeView(1)">Big Lot</v-tab>
                    <v-tab v-if="mandate == 1" v-on:click="changeView(2)">Mandate</v-tab>
                    <v-tab v-if="fastTrade == 1" v-on:click="changeView(3)">Fast Trade</v-tab>
                    <v-tab v-if="largeExposure == 1" v-on:click="changeView(4)">Large Exposure</v-tab>
                    <v-tab v-if="lockingPosition == 1" v-on:click="changeView(5)">Locking Position</v-tab>
                    <v-tab v-if="profitTaker == 1" v-on:click="changeView(6)">Profit Taker</v-tab>
                    <v-tab v-if="tradeOnCredit == 1" v-on:click="changeView(7)">Trade On Credit</v-tab>
                    <v-tab v-if="watchlist == 1" v-on:click="changeView(8)">Watchlist</v-tab>
                    <v-tab v-if="withdrawDeposit == 1" v-on:click="changeView(9)">Withdraw&Deposit</v-tab>
                    <v-tab v-if="tickCheck == 1" v-on:click="changeView(10)">Tick Check</v-tab>
                </v-tabs>
            </v-col>
        </v-row>

        <BigLot v-if="selectedKey == 1" />
        <Mandate v-if="selectedKey == 2" />
        <FastTrade v-if="selectedKey == 3" />
        <LargeExposure v-if="selectedKey == 4" />
        <LockingPosition v-if="selectedKey == 5" />
        <ProfitTaker v-if="selectedKey == 6" />
        <TradeOnCredit v-if="selectedKey == 7" />
        <WatchList v-if="selectedKey == 8" />
        <WithdrawDeposit v-if="selectedKey == 9" />
        <TickCheck v-if="selectedKey == 10" />
    </div>
</template>

<script>
import BigLot from "./components/search/ComponentBigLot";
import Mandate from "./components/search/ComponentMandate";
import FastTrade from "./components/search/ComponentFastTrade";
import LargeExposure from "./components/search/ComponentLargeExposure";
import LockingPosition from "./components/search/ComponentLockingPosition";
import ProfitTaker from "./components/search/ComponentProfitTaker";
import TradeOnCredit from "./components/search/ComponentTradeOnCredit";
import WatchList from "./components/search/ComponentWatchList";
import WithdrawDeposit from "./components/search/ComponentWithdrawDeposit";
import TickCheck from "./components/search/ComponentTickCheck";
import { permission } from "@components/mixins/permission";

export default {
    components: {
        BigLot,
        Mandate,
        FastTrade,
        LargeExposure,
        LockingPosition,
        ProfitTaker,
        TradeOnCredit,
        WatchList,
        WithdrawDeposit,
        TickCheck
    },
    mixins: [permission],
    data () {
        return {
            selectedKey: 1,
            bigLot: 0,
            mandate: 0,
            fastTrade: 0,
            largeExposure: 0,
            lockingPosition: 0,
            profitTaker: 0,
            tradeOnCredit: 0,
            watchlist: 0,
            withdrawDeposit: 0,
            tickCheck: 0
        };
    },
    methods: {
        changeView (key) {
            this.selectedKey = key;
        }
    },
    mounted () {
        const userFunction = this.getFunction("alert");

        if (userFunction.includes("bigLot")) this.bigLot = 1;
        if (userFunction.includes("mandate")) this.mandate = 1;
        if (userFunction.includes("fastTrade")) this.fastTrade = 1;
        if (userFunction.includes("largeExposure")) this.largeExposure = 1;
        if (userFunction.includes("lockingPosition")) this.lockingPosition = 1;
        if (userFunction.includes("profitTaker")) this.profitTaker = 1;
        if (userFunction.includes("tradeOnCredit")) this.tradeOnCredit = 1;
        if (userFunction.includes("watchlist")) this.watchlist = 1;
        if (userFunction.includes("withdrawDeposit")) this.withdrawDeposit = 1;
        if (userFunction.includes("tickCheck")) this.tickCheck = 1;
    }
};
</script>
