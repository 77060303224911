<template>
    <div>
        <!-- <v-text-field class="mt-n2 mb-2" v-model="search" append-icon="mdi-search"
            label="Search" single-line hide-details>
        </v-text-field>
        -->
        <v-data-table dense :search="search" :headers="headers" :items="dataSource" class="elevation-1"
            :items-per-page="itemsPerPage">

            <template v-slot:item.current_value="{ item }" v-if="name == 'mandate'">
                {{ item.current_value.toFixed(2) }}
            </template>

            <template v-slot:item.exposure="{ item }" v-if="name == 'large_exposure'">
                {{ item.exposure.toFixed(2) }}
            </template>

            <template v-slot:item.locking_position="{ item }" v-if="name == 'locking_position'">
                {{ item.locking_position.toFixed(2) }}
            </template>

            <template v-slot:item.profit_today="{ item }" v-if="name == 'profit_taker'">
                {{ item.profit_today.toFixed(2) }}
            </template>

            <template v-slot:item.equity="{ item }" v-if="name == 'trade_on_credit'">
                {{ item.equity.toFixed(2) }}
            </template>

            <template v-slot:item.balance="{ item }" v-if="name == 'trade_on_credit'">
                {{ item.balance.toFixed(2) }}
            </template>

            <template v-slot:item.credit="{ item }" v-if="name == 'trade_on_credit'">
                {{ item.credit.toFixed(2) }}
            </template>

            <template v-slot:item.amount="{ item }" v-if="name == 'wd'">
                {{ item.amount.toFixed(2) }}
            </template>

        </v-data-table>
    </div>
</template>

<script>
export default {
    props: {
        name: "",
        headers: null,
        dataSource: null,
        itemsPerPage: null
    },
    data () {
        return {
            search: ""
        };
    }
};
</script>
